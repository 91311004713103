<template>
  <dropdown v-if="availableLocales.length > 1">
    <template #activator="{ attrs, toggleDropdownVisibility, isOpened }">
      <btn
        v-bind="attrs"
        :variant="BtnVariant.TEXT"
        :size="BtnSize.LG"
        class="gap-2"
        @click="toggleDropdownVisibility()"
      >
        <component :is="flagComponentByLocale[locale]" class="h-6" />
        {{ languageNameByLocale[locale] }}
        <icon
          :icon="Arrow"
          :class="{ 'rotate-180': isOpened }"
          class="transition-transform duration-300"
        />
      </btn>
    </template>
    <template #default="{ closeDropdown }">
      <div class="text-center">{{ t('common.language') }}</div>
      <hr class="my-2" />
      <button
        v-for="availableLocale in availableLocales"
        :key="availableLocale"
        class="text-on-surface px-4 py-2 text-left flex"
        @click="
          () => {
            setLocale(availableLocale);
            closeDropdown();
          }
        "
      >
        <span class="flex flex-1 gap-2">
          <component :is="flagComponentByLocale[availableLocale]" class="h-6" />
          {{ languageNameByLocale[availableLocale] }}
        </span>
        <icon
          v-if="locale === availableLocale"
          class="text-success"
          :icon="Check"
        />
      </button>
    </template>
  </dropdown>
</template>

<script setup lang="ts">
import { Btn, BtnSize, BtnVariant } from '~/components/btn';
import Arrow from '@/assets/icons/arrow-down.svg?component';
import Check from '@/assets/icons/check.svg?component';
import { Icon } from '@/components/icon';
import { Dropdown } from '~/components/dropdown';
import { useOrganizationStore } from '~/store/organization';
import { Locale } from '@exfluential/language';
import FlagIconUsSvg from 'flag-icons/flags/4x3/us.svg?component';
import FlagIconFrSvg from 'flag-icons/flags/4x3/fr.svg?component';
import FlagIconEsSvg from 'flag-icons/flags/4x3/es.svg?component';
import FlagIconPtSvg from 'flag-icons/flags/4x3/pt.svg?component';
import FlagIconDeSvg from 'flag-icons/flags/4x3/de.svg?component';
import FlagIconCaSvg from 'flag-icons/flags/4x3/ca.svg?component';
import FlagIconJpSvg from 'flag-icons/flags/4x3/jp.svg?component';

const { t, locale, locales, setLocale } = useI18n();

const { organization } = storeToRefs(useOrganizationStore());

const availableLocales = computed(() => {
  if (!organization.value) {
    return [];
  }

  return organization.value.locale.available;
});

const languageNameByLocale = computed(() =>
  locales.value.reduce<Record<string, string>>((acc, locale) => {
    if (!locale.name) {
      throw new Error(
        `Language name is missing in i18n config for locale code: "locale.code"`,
      );
    }
    acc[locale.code] = locale.name;

    return acc;
  }, {}),
);

const flagComponentByLocale: Record<Locale, Component> = {
  [Locale.EN_US]: FlagIconUsSvg,
  [Locale.FR]: FlagIconFrSvg,
  [Locale.FR_CA]: FlagIconCaSvg,
  [Locale.ES]: FlagIconEsSvg,
  [Locale.PT]: FlagIconPtSvg,
  [Locale.DE]: FlagIconDeSvg,
  [Locale.JA_JP]: FlagIconJpSvg,
};
</script>
